import { DEV_URL, ERP_AUTH } from "../config/constants";
import { handleError, handleResponse } from "./apiUtils";

import axios from "axios";

//TODO
// export function getUsers() {
//   return axios
//     .get("https://jsonplaceholder.typicode.com/users")
//     .then(handleResponse)
//     .catch(handleError);
// }

export function getProductsTechData(data) {
  return axios
    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProductsCards(data) {
  return axios
    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProductsCardsQuantity(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/getquantity", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function exportBasketApi(token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/exportbasket", null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getActiveTasks(body, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/index/activetasks", body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getFinishedTasks(body, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/index/finishedtasks", body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProductsCardsPrices(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/getprices", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getProductsCardsCount(data) {
  return axios

    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getMenu() {
  return axios

    .get(
      DEV_URL?.PROD_ERP +
        "/StoreMenuItems?select=id,parent_id,text,X_fullName,X_URL,FULLPATH&where=x_visible+eq+'A'",
      {
        headers: {
          Authorization: ERP_AUTH,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export function getSearchResult(data) {
  return axios

    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function modifyMenu(data) {
  return axios

    .post(DEV_URL?.PROD_ERP + "/batch", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getSignInToken(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/login", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getFirmPriceList(data) {
  return axios
    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getPeriod(data) {
  return axios
    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getAssortmentGroups(data) {
  return axios
    .post(DEV_URL?.PROD_ERP + "/query", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: ERP_AUTH,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function verifyReCaptcha(body) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/verifyrecaptcha", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getBasket(token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/getbasket", null, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function updateIndex(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/index/createtask", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function modifyBasket(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/modifybasket", data, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function conversionsApi(data) {
  return axios
    .post(DEV_URL?.CONVERSION_API_URL, data, {
      // headers: {
      //   Authorization: "Token " + token,
      // },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteFromBasket(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/removefrombasket", data, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteBasket(token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/removebasket", null, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function sendOffer(token, data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/sendoffer", data, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function importBasket(token, data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/importbasket", data, {
      headers: {
        Authorization: "Token " + token,
        // TODO for file upload
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getCredit(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/getusercredit", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function sendOrder(data) {
  return axios

    .post(DEV_URL?.PROD_AUTH + "/api/sendorder", data, {
      headers: {
        // Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getHmacApi(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/gethmac", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getSignApi(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/getsign", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function callPaymentGateway(data) {
  return axios
    .post(DEV_URL?.PROD_BANK, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getKeys(body) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/getkeys", body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function log_out(token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/logout", null, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function authenticate(token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/auth", null, {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function createAccount(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/createuser", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function registerAccount(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/registeruser", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getInactiveUsers(token) {
  return axios
    .get(DEV_URL?.PROD_AUTH + "/api/inactiveusers", {
      headers: {
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getInactiveUsersFirm(abraId) {
  return axios
    .get(
      DEV_URL?.PROD_ERP + "/firms?select=id,name&where=id+eq'" + abraId + "'",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: ERP_AUTH,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
}

export function activateUser(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/activateuser", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function accountActivation(uidb64, token) {
  return axios
    .get(DEV_URL?.PROD_AUTH + "/api/activate/" + uidb64 + "/" + token)
    .then(handleResponse)
    .catch(handleError);
}

export function updateAccount(data, token) {
  return axios
    .put(DEV_URL?.PROD_AUTH + "/api/updateuser", data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function updateFirm(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/updatefirm", data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function updateFirmWithFinstat(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/updatefirmfinstat", data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function updateShippingAddress(data, token) {
  return axios
    .put(DEV_URL?.PROD_AUTH + "/api/updateshippingaddress", data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteteShippingAddress(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/deleteshippingaddress", data, {
      headers: {
        "Content-Type": "application/json",

        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function changePassword(data, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/changepassword", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function forgottenPassword(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/resetpassword", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function resetPassword(data, uidb64, token) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/reset/" + uidb64 + "/" + token, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function hCaptchaValidate(data) {
  return axios
    .post(DEV_URL?.PROD_AUTH + "/api/verifycaptcha", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(handleResponse)
    .catch(handleError);
}
